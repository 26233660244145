<template>
  <div class="d-flex kt-wizard-v4" id="kt_wizard_v4" data-ktwizard-state="step-first">
    <!--begin: Form Wizard Nav -->
    <div class="kt-wizard-v4__nav">

      <!--doc: Remove "kt-wizard-v4__nav-items--clickable" class and also set 'clickableSteps: false' in the JS init to disable manually clicking step titles -->
      <div class="kt-wizard-v4__nav-items kt-wizard-v4__nav-items--clickable">
        <div class="kt-wizard-v4__nav-item" data-ktwizard-type="step" data-ktwizard-state="current">
          <div class="kt-wizard-v4__nav-body">
            <div class="kt-wizard-v4__nav-number">
              1
            </div>
            <div class="kt-wizard-v4__nav-label">
              <div class="kt-wizard-v4__nav-label-title">
                {{ $t("COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.STEP.ONE.TITLE") }}
              </div>
              <div class="kt-wizard-v4__nav-label-desc">
                {{ $t("COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.STEP.ONE.DESC") }}
              </div>
            </div>
          </div>
        </div>
        <div class="kt-wizard-v4__nav-item" data-ktwizard-type="step">
          <div class="kt-wizard-v4__nav-body">
            <div class="kt-wizard-v4__nav-number">
              2
            </div>
            <div class="kt-wizard-v4__nav-label">
              <div class="kt-wizard-v4__nav-label-title">
                {{ $t("COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.STEP.TWO.TITLE") }}
              </div>
              <div class="kt-wizard-v4__nav-label-desc">
                {{ $t("COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.STEP.TWO.DESC") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--end: Form Wizard Nav -->
    <portlet body-fit body-class="border-start-0" class="border-start-0">
      <template v-slot:body>
        <div class="kt-grid">
          <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v4__wrapper">

            <!--begin: Form Wizard Form-->

            <div class="kt-form" id="kt_form">
              <!--begin: Form Wizard Step 1-->
              <div class="kt-wizard-v4__content other" data-ktwizard-type="step-content">
                <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.STEP.ONE.DESC") }}</div>
                <div class="kt-form__section kt-form__section--first">
                  <div class="kt-wizard-v4__form">
                    <b-row>
                      <b-col sm="6" md="12">
                        <b-form-group id="input-group-ug-title" :label="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.LABEL.title')" label-for="ug-title">
                          <b-form-input id="ug-title" :class="[direction_rtl]" v-model="formData.title['zh-UG']" :state="validateState('zh-UG', 'title')" aria-describedby="ug-title-live-feedback" :placeholder="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.PLACEHOLDER.title_ug')" />
                          <b-form-invalid-feedback id="ug-title-live-feedback">
                            {{ $t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.FEEDBACK.title') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="12">
                        <b-form-group id="input-group-ug-describe" :label="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.LABEL.describe')" label-for="ug-describe">
                          <b-form-textarea id="ug-describe" :class="[direction_rtl]" v-model="formData.describe['zh-UG']" :state="validateState('zh-UG', 'describe')" aria-describedby="ug-describe-live-feedback" :placeholder="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.PLACEHOLDER.describe_ug')" />
                          <b-form-invalid-feedback id="ug-describe-live-feedback">
                            {{ $t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.FEEDBACK.describe') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="12">
                        <b-form-group id="input-group-content-ug" :state="validateState('zh-UG', 'content')" aria-contentdby="content-ug-live-feedback" :label="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.LABEL.content')" label-for="content-ug">
                          <simple-editor v-model="formData.content['zh-UG']" :only="['text', 'image']" />
                          <b-form-invalid-feedback id="content-ug-live-feedback">
                            {{ $t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.FEEDBACK.content') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Step 1-->

              <!--begin: Form Wizard Step 2-->
              <div class="kt-wizard-v4__content other" data-ktwizard-type="step-content" data-ktwizard-state="current">
                <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.STEP.TWO.DESC") }}</div>
                <div class="kt-form__section kt-form__section--first">
                  <div class="kt-wizard-v4__form">
                    <b-row>
                      <b-col sm="6" md="12">
                        <b-form-group id="input-group-cn-title" :label="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.LABEL.title')" label-for="cn-title">
                          <b-form-input id="cn-title" :class="[direction_ltr]" v-model="formData.title['zh-CN']" :state="validateState('zh-CN', 'title')" aria-describedby="cn-title-live-feedback" :placeholder="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.PLACEHOLDER.title_cn')" />
                          <b-form-invalid-feedback id="cn-title-live-feedback">
                            {{ $t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.FEEDBACK.title') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="12">
                        <b-form-group id="input-group-cn-describe" :label="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.LABEL.describe')" label-for="cn-describe">
                          <b-form-textarea max-rows="5" id="cn-describe" :class="[direction_ltr]" v-model="formData.describe['zh-CN']" :state="validateState('zh-CN', 'describe')" aria-describedby="cn-describe-live-feedback" :placeholder="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.PLACEHOLDER.describe_cn')" />
                          <b-form-invalid-feedback id="cn-describe-live-feedback">
                            {{ $t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.FEEDBACK.describe') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="12">
                        <b-form-group id="input-group-content-cn" :state="validateState('zh-CN', 'content')" aria-contentdby="content-cn-live-feedback" :label="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.LABEL.content')" label-for="content-cn">
                          <simple-editor v-model="formData.content['zh-CN']" :only="['text', 'image']" />
                          <b-form-invalid-feedback id="content-cn-live-feedback">
                            {{ $t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.FEEDBACK.content') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Step 2-->

              <div class="kt-wizard-v4__other">
                <b-row>
                  <b-col sm="12" md="3">
                    <b-form-group id="input-group-cover_image" :state="validateState('cover_image')" aria-contentdby="cover_image-live-feedback"  :description="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.PLACEHOLDER.cover_image')" :label="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.LABEL.cover_image')" label-for="cover_image">
                      <image-uploader v-model="formData.cover_image" :upload-form="{strategy: 'cover_image'}" accepted-file-types="image/png, image/jpg, image/jpeg, image/bmp" />
                      <b-form-invalid-feedback id="cover_image-live-feedback">
                        {{ $t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.FEEDBACK.cover_image') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="3">
                    <b-form-group id="input-group-avatar_image" :description="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.PLACEHOLDER.ticket_image')" :label="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.LABEL.ticket_image')" label-for="avatar_image">
                      <image-uploader v-model="formData.ticket_image" :upload-form="{strategy: 'ticket_image'}" accepted-file-types="image/png, image/jpg, image/jpeg, image/bmp" />
                      <b-form-invalid-feedback id="avatar_image-live-feedback">
                        {{ $t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.FEEDBACK.ticket_image') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6" md="3">
                    <b-form-group id="input-group-amount" :state="validateState('amount')" aria-contentdby="amount-live-feedback" :label="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.LABEL.amount')" label-for="amount">
                      <b-form-input class="direction-ltr" style="width: 100%;" id="amount" min="0" v-model="formData.amount" type="number" :placeholder="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.PLACEHOLDER.amount')" />
                      <b-form-invalid-feedback id="amount-live-feedback">
                        {{ $t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.FEEDBACK.amount') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6" md="3">
                    <b-form-group id="input-group-total" :label="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.LABEL.total')" label-for="total">
                      <b-form-input class="direction-ltr" style="width: 100%;" id="total" min="0" v-model="formData.total" type="number" :placeholder="$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.PLACEHOLDER.total')" />
                    </b-form-group>
                  </b-col>
                  <b-col sm="6" md="3">
                    <b-form-group id="input-group-is_show" :label="$t('COMMON.FORM.LABEL.is_show')" label-for="is_show">
                      <b-form-radio-group
                              id="is_show"
                              v-model="formData.is_show"
                              :options="yesOrNot"
                              buttons />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <!--begin: Form Actions -->
              <div class="kt-form__actions">
                <button class="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-prev">
                  {{ $t("COMMON.FORM.BUTTONS.PREV") }}
                </button>
                <button class="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-submit" @click="onSubmit">
                  {{ $t("COMMON.FORM.BUTTONS.SUBMIT") }}
                </button>
                <button class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-next">
                  {{ $t("COMMON.FORM.BUTTONS.NEXT") }}
                </button>
              </div>
              <!--end: Form Actions -->
            </div>
            <!--end: Form Wizard Form-->
          </div>
        </div>
      </template>
    </portlet>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import ImageUploader from "@v@/partials/layout/ImageUploader";
  import SimpleEditor from "@v@/components/SimpleEditor";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import progressHistory from "@m@/article.progress.history.mixin";
  import KTWizard from "@/assets/js/wizard";
  import KTUtil from "@/assets/js/util";
  import directionMixin from "@m@/common/direction.mixin";
  export default {
    name: "index",
    components: {Portlet, ImageUploader, SimpleEditor},
    props: {
      action: {
        type: String,
        default: 'create'
      },
      article_id: {
        type: [String, Number],
      },
      id: {
        type: [String, Number],
      },
    },
    mixins: [progressHistory, validateStateMixin, validationMixin, sweetAlertMixin, directionMixin],
    created() {
      if (this.action === 'edit'){
        this.getArticleProgressHistory(this.id)
          .then(res=>{
            this.formData = Object.assign({}, this.formData, res.data);
          })
          .catch(err=>{
            console.info('getArticleProgressHistory.err: ', err);
          })
      }
    },
    mounted() {
      this.wizardHandler();
    },
    validations(){
      let validates = {
        amount: { required },
        total: { required },
        cover_image: { required },
      };
      validates['content'] = {
        "zh-UG": {required},
        "zh-CN": {required},
      };
      validates['title'] = {
        "zh-UG": {required},
        "zh-CN": {required},
      };
      validates['describe'] = {
        "zh-UG": {required},
        "zh-CN": {required},
      };
      return {
        formData: validates
      }
    },
    data() {
      return {
        loading: false,
        yesOrNot: [
          { text: this.$t('COMMON.FORM.OPTIONS.YES'), value: true },
          { text: this.$t('COMMON.FORM.OPTIONS.NO'), value: false },
        ],
        formData: {
          amount: null,
          total: 0,
          ticket_image: null,
          cover_image: null,
          is_show: true,
          content: {
            "zh-UG": [],
            "zh-CN": [],
          },
          title: {
            "zh-UG": '',
            "zh-CN": '',
          },
          describe: {
            "zh-UG": '',
            "zh-CN": '',
          },
        },
      }
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning({message: this.$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.TIPS.VALIDATION_TIPS')});
        }
        if (this.action === 'create'){
          let { article_id } = this;
          this.storeArticleProgressHistory(article_id, this.formData)
            .then(()=> {
              this.$router.replace({ name: `article.progress-history`, params: {article_id} });
            }).catch(err=>{
              console.info('storeArticleProgressHistory.err: ', err)
            })
        }else if ( this.action === 'edit' ){
          delete this.formData.lang;
          this.updateArticleProgressHistory(this.id, this.formData)
            .then(()=>{
              this.success({ message: this.$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.TIPS.EDIT_SUCCESS')})
            })
            .catch(err=>{
              console.info('updateArticleProgressHistory.err: ', err)
            })
        }
      },
      wizardHandler(){
        // Initialize form wizard
        this.wizard = new KTWizard('kt_wizard_v4', {
          startStep: 1, // initial active step number
          clickableSteps: true  // allow step clicking
        });
        this.$nextTick(function () {
          const self = this;
          // Validation before going to next page
          this.wizard.on('beforeNext', function(wizardObj) {
            // console.info('beforeNext.wizardObj: ', wizardObj);
            let { currentStep } = wizardObj;
            self.stepValidHandler(currentStep, wizardObj);
          });

          this.wizard.on('beforePrev', function(wizardObj) {
            let { currentStep } = wizardObj;
            self.stepValidHandler(currentStep, wizardObj);
          });

          // Change event
          this.wizard.on('change', function(wizard) {
            KTUtil.scrollTop();
          });
        });
      },
      validHandler(formName = 'title', parent = null, wizardObj){
        if( formName ){
          if (parent){
            this.$v.formData[parent][formName].$touch();
          }else {
            this.$v.formData[formName].$touch();
          }
        }else {
          this.$v.formData.$touch();
        }
        // if (this.$v.formData[formName].$anyError === true) {
        if (this.$v.formData.$anyError === true) {
          wizardObj.stop();
          this.warning({message: this.$t('COMPONENTS.FORMS.PROGRESS_HISTORY_FORM.FORM.TIPS.VALIDATION_TIPS')});
          return false;
        }else {
          return true;
        }
      },
      stepValidHandler(currentStep, wizardObj){
        switch (currentStep) {
          case 1:
            this.validHandler('zh-UG', 'content', wizardObj);
            break;
          case 2:
            this.validHandler('zh-CN', 'content', wizardObj);
            break;
        }
      },
    }
  }
</script>

<style lang="scss">
  @import "@/assets/sass/pages/wizard/wizard-4";
</style>
